import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/shared-layout";
// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
  fontSize: 30,
}

const paragraphStyles = {
  marginBottom: 48,
}
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }

// markup
const NotFoundPage = () => {
  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showStaffList={false}
          showYearSemTerm={true}
          showFilter={false}
        >
          <main style={pageStyles} className="m-4 mt-2 border shadow-lg rounded-xl">
            <title>Not found</title>
            <h1 style={headingStyles} className="text-barPrimary">PAGE NOT FOUND</h1>
            <p style={paragraphStyles}>
              Sorry{" "}
              <span role="img" aria-label="Pensive emoji">
                😔
              </span>{" "}
              we couldn’t find what you were looking for.
              <br /><br />
              <Link to="/">
                <div className="text-barPrimary">Go Dashboard</div>
              </Link>.
            </p>
          </main>
        </Layout>
      }
    </>
  )
}

export default NotFoundPage
